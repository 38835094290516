.parallax_section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
  z-index: 1;
}

.parallax-section-admin {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 60%;
  z-index: 1;
}

.portadaGaleria {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
}

.parallax_img {
  filter: blur(4px);
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px);
  background-size: 100%;
  z-index: -1;
}

.text {
  position: absolute;
}

.titulo_viaje {
  font-size: 50px;
}

.section2 {
  /* background-color: rgba(18, 18, 18, 1); */
}

.rectanguloTitulo {
  width: 10px;
  background-color: #00bdbb;
}

.card-vijes-1 {
  height: 250px;
  background-image: linear-gradient(
      rgba(34, 34, 34, 0.7),
      rgba(34, 34, 34, 0.7)
    ),
    url(../images/cardViajes2.jpg);
  background-size: cover;
  border-radius: 10px;
}

.card-vijes-2 {
  height: 250px;
  background-image: linear-gradient(
      rgba(34, 34, 34, 0.7),
      rgba(34, 34, 34, 0.7)
    ),
    url(../images/cardViajes1.jpg);
  background-size: cover;
  border-radius: 10px;
}

.card-vijes-3 {
  height: 250px;
  background-image: linear-gradient(
      rgba(34, 34, 34, 0.7),
      rgba(34, 34, 34, 0.7)
    ),
    url(../images/cardViajes5.jpg);
  background-size: cover;
  border-radius: 10px;
}

.card-vijes-4 {
  height: 250px;
  background-image: linear-gradient(
      rgba(34, 34, 34, 0.7),
      rgba(34, 34, 34, 0.7)
    ),
    url(../images/cardViajes4.jpg);
  background-size: cover;
  border-radius: 10px;
}

.contentViajes {
  margin-top: -150px !important;
}

.circleIcons {
  height: 60px;
  width: 60px;
  background-color: cornflowerblue;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}

.grow {
  transition: transform 0.25s !important; /* Animation */
}

.grow:hover {
  transform: scale(1.05);
}

/* .logo {
    align-self: center;
    margin: auto;
}
*/
@media (max-width: 768px) {
  .parallax_section {
    height: 100%;
  }

  .titulo_viaje {
    font-size: 40px;
  }

  .portadaGaleria {
    height: 100vh;
  }

  .contentViajes {
    margin-top: unset !important;
  }

  .textColor {
    color: #212529 !important;
  }
}
