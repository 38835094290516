.titulo {
    font-family: "Tw Cen MT";
    font-size: 36px;
    font-weight: bolder;
    margin-bottom: 2vh;
}

.texto {
    font-size: x-large;
    font-family: "Tw Cen MT";
    text-align: justify;
}

.texto2 {
    font-size: x-large;
    font-family: "Tw Cen MT";
    text-align: center;
}

.sizeGaleria {
    height: 100vh;
    background-color: #121212;
}

@media (max-width: 768px) {
    .sizeGaleria {
        height: auto;
    }
}

.fbVideoGaleria {
    border: none;
    overflow: hidden;
    position: fixed;
}
