/* .portadaGaleria::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 60vh;
    backdrop-filter: blur(0px);
    pointer-events: none;
} */

.portadaViajes {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(34, 34, 34, 0.4);
  /* background-image: linear-gradient(
            rgba(34, 34, 34, 0.4),
            rgba(34, 34, 34, 0.4)
        ),
        url(../images/viajesBackground.jpg);
    background-size: cover; */

  /* Set a specific height */

  /* Create the parallax scrolling effect */
  /* background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
}

.parallax-img {
  filter: blur(4px);
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px);
  background-size: 100%;
  z-index: -1;
}

.rectanguloTitulo {
  width: 10px;
  background-color: #00bdbb;
}

.card_vijes_1 {
  height: 250px;
  background-image: linear-gradient(
      rgba(34, 34, 34, 0.7),
      rgba(34, 34, 34, 0.7)
    ),
    url(../../assets/images/cardViajes2.jpg);
  background-size: cover;
  border-radius: 10px;
}

.card_vijes_2 {
  height: 250px;
  background-image: linear-gradient(
      rgba(34, 34, 34, 0.7),
      rgba(34, 34, 34, 0.7)
    ),
    url(../../assets/images/cardViajes1.jpg);
  background-size: cover;
  border-radius: 10px;
}

.card_vijes_3 {
  height: 250px;
  background-image: linear-gradient(
      rgba(34, 34, 34, 0.7),
      rgba(34, 34, 34, 0.7)
    ),
    url(../../assets/images/cardViajes5.jpg);
  background-size: cover;
  border-radius: 10px;
}

.card_vijes_4 {
  height: 250px;
  background-image: linear-gradient(
      rgba(34, 34, 34, 0.7),
      rgba(34, 34, 34, 0.7)
    ),
    url(../../assets/images/cardViajes4.jpg);
  background-size: cover;
  border-radius: 10px;
}

.contentViajes {
  margin-top: -150px !important;
}

.circleIcons {
  height: 60px;
  width: 60px;
  background-color: cornflowerblue;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}

.grow {
  transition: transform 0.25s !important; /* Animation */
}

.grow:hover {
  transform: scale(1.05);
}

/* .logo {
    align-self: center;
    margin: auto;
}
*/
@media (max-width: 768px) {
  .contentViajes {
    margin-top: unset !important;
  }

  .textColor {
    color: #212529 !important;
  }
}
