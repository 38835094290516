.viajesCard {
  height: 500px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.textViajesCard {
  font-family: "TW Cen MT";
}

.viajesAltura {
  height: 100vh;
}

@media (max-width: 768px) {
  .viajesAltura {
    height: auto;
  }
}
