.grow {
    transition: transform 0.25s !important; /* Animation */
}

.grow:hover {
    transform: scale(1.1);
}

.growCard {
    transition: transform 0.25s !important; /* Animation */
}

.growCard:hover {
    transform: scale(1.03);
}
