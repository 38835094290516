.imgViajeGratis {
  object-fit: fill;
  width: 100%;
  height: 100vh;
}

.btn {
  border-color: white;
  color: white;
  font-weight: bold;
}

.btn:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .imgViajeGratis {
    height: 50vh;
  }
}
