.portadaHome {
  width: 100%;
  height: 94vh;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      rgba(142, 142, 142, 0.4),
      rgba(142, 142, 142, 0.4)
    ),
    url(../../assets/images/Background.jpg);
  background-size: cover;
}

.logo {
  align-self: center;
  margin: auto;
}

@media (max-width: 768px) {
  .logo {
    width: 50%;
  }
}
