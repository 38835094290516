.portadaViajaGratis {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(34, 34, 34, 0.4);
}

.parallax_img {
  filter: blur(4px);
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px);
  background-size: 100%;
  z-index: -1;
}

.parallax_section {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
  z-index: 1;
}

.btnFAQ {
  border-color: #303964;
  color: #303964;
  font-weight: bold;
}

.btnFAQ:hover {
  background-color: #303964;
  color: white;
}

@media (max-width: 768px) {
  .parallax-section {
    height: 100%;
  }

  .titulo-viaje {
    font-size: 40px;
  }

  .portadaGaleria {
    height: 100vh;
  }

  .contentViajes {
    margin-top: unset !important;
  }

  .textColor {
    color: #212529 !important;
  }
}
