.viajesCard {
  height: 500px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.textViajesCard {
  font-family: "TW Cen MT";
}

.viajesAltura {
  height: 100vh;
}

.textInfo {
  /* visibility: hidden; */
  /* opacity: 0; */
  transition: visibility 0s, opacity 0.5s linear;
}

.viajesCard:hover .textInfo {
  visibility: visible;
  opacity: 1;
}

.contenedor {
  height: 100%;
  padding: 5% 0;
}
/* 
h1 {
  color: #fcfbfa;
} */

.container_foto {
  background-color: rgba(0, 0, 0);
  padding: 0;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 500px;
  margin: 5px;
}

.container_foto article {
  padding: 10%;
  z-index: 1;
  position: absolute;
  bottom: 0;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.container_foto h2 {
  color: #fff;
  font-weight: 800;
  font-size: 25px;
  border-bottom: #fff solid 1px;
}

.container_foto h4 {
  font-weight: 300;
  color: #fff;
  font-size: 16px;
}

.container_foto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  opacity: 0.3;
  -webkit-transition: all 4s ease;
  -moz-transition: all 4s ease;
  -o-transition: all 4s ease;
  -ms-transition: all 4s ease;
  transition: all 4s ease;
}

.ver_mas {
  background-color: #393e5d;
  position: absolute;
  width: 100%;
  height: 140px;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transform: translate(0px, 70px);
  -webkit-transform: translate(0px, 70px);
  -moz-transform: translate(0px, 70px);
  -o-transform: translate(0px, 70px);
  -ms-transform: translate(0px, 70px);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ver_mas span {
  font-size: 40px;
  color: #fff;
  position: relative;
  margin: 0 auto;
  width: 100%;
  top: 13px;
}

/*hovers*/

.container_foto:hover {
  cursor: pointer;
}

.container_foto:hover img {
  opacity: 0.1;
  filter: blur(4px);
  transform: scale(1.3);
}

.container_foto:hover article {
  transform: translate(2px, -129px);
  -webkit-transform: translate(2px, -129px);
  -moz-transform: translate(2px, -129px);
  -o-transform: translate(2px, -129px);
  -ms-transform: translate(2px, -129px);
}

.container_foto:hover .ver_mas {
  transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  opacity: 1;
}

@media (max-width: 768px) {
  .viajesAltura {
    height: auto;
  }
}
