.portadaFaq {
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(34, 34, 34, 0.4);
}

.parallax-img {
    filter: blur(4px);
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(-1px);
    background-size: 100%;
    z-index: -1;
}

.faq-drawer {
    margin-bottom: 30px;
}

.faq-drawer__content-wrapper {
    font-size: 1.25em;
    line-height: 1.4em;
    max-height: 0px;
    overflow: hidden;
    transition: 0.5s ease-in-out;
}

.faq-drawer__title {
    border-top: #000 1px solid;
    cursor: pointer;
    display: block;
    font-size: 1.25em;
    font-weight: 700;
    padding: 30px 0 0 0;
    position: relative;
    margin-bottom: 0;
    transition: all 0.5s ease-out;
}

.faq-drawer__title::after {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: " ";
    display: inline-block;
    float: right;
    height: 10px;
    left: 2px;
    position: relative;
    right: 20px;
    top: 2px;
    transform: rotate(135deg);
    transition: 0.35s ease-in-out;
    vertical-align: top;
    width: 10px;
}

/* OPTIONAL HOVER STATE */
.faq-drawer__title:hover {
    color: #4e4b52;
}

.faq-drawer__trigger:checked
    + .faq-drawer__title
    + .faq-drawer__content-wrapper {
    max-height: 350px;
}

.faq-drawer__trigger:checked + .faq-drawer__title::after {
    transform: rotate(-45deg);
    transition: 0.25s ease-in-out;
}

input[type="checkbox"] {
    display: none;
}
